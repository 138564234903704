<template>
    <v-container>
        <base-material-card icon="fas fa-credit-card" title="Cuenta" class="px-5 py-3">
            <v-row>
                <v-col cols="12">
                    <v-row>
                        <v-col cols="6">
                            <v-text-field label="Banco" v-model="cuenta.banco" :error-messages="errors.banco" class="required">
                            </v-text-field>
                            <v-text-field label="Razón social" v-model="cuenta.razon_social" :error-messages="errors.razon_social" class="required">
                            </v-text-field>
                            <v-text-field label="Número" v-model="cuenta.numero" :error-messages="errors.numero" class="required">
                            </v-text-field>
                        </v-col>
                    </v-row>
                </v-col>
                <v-col cols="12">
                    <v-btn color="primary" @click="save()" :loading="loading">Guardar</v-btn>
                    <v-btn @click="$router.push({name: 'Cuenta'})" :loading="loading">Cancelar</v-btn>
                </v-col>
            </v-row>
        </base-material-card>
    </v-container>
</template>
<script>
    const MODEL = {
        nombre: null,
        codigo: null
    }
    export default {
        data: () => ({
            cuenta: {},
            errors: {},
            loading: false
        }),
        mounted() {
            if (this.$route.params.id) {
                this.load()
            }
        },
        methods: {
            load() {
                this.$http.get(`/cuentas/${this.$route.params.id}`).then(response => {
                    this.cuenta = response.data
                })
            },
            save() {
                this.errors = {}
                this.loading = true
                if (this.$route.params.id) {
                    this.update()
                    return
                }
                this.create()
            },
            create() {
                this.$http.post('/cuentas', this.cuenta).then(response => {
                    this.$router.push({ name: 'Cuenta' })
                }).catch(error => {
                    switch (error.response.status) {
                        case 422:
                            this.errors = this.errors = error.response.data.errors
                            break;

                        default:
                            break;
                    }
                }).finally(() => {
                    this.loading = false
                })
            },
            update() {
                this.$http.put(`/cuentas/${this.$route.params.id}`, this.cuenta).then(response => {
                    this.$router.push({ name: 'Cuenta' })
                }).catch(error => {
                    switch (error.response.status) {
                        case 422:
                            this.errors = error.response.data.errors
                            break;

                        default:
                            break;
                    }
                }).finally(() => {
                    this.loading = false
                })
            }
        }
    }
</script>